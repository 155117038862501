import React, { useEffect } from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import heroAnimation from '../../../assets/animations/potato.riv';
import { usePopup } from '../../Popups/PopupContext.js';

const HeroComponent = () => {
	const { heroState, setHeroState, heroBackground } = usePopup();

	const { rive, RiveComponent } = useRive({
		src: heroAnimation,
		stateMachines: 'State Machine 1',
		//animations: 'idle',
		autoplay: true,
		onInit: (riveInstance) => {
			//console.log('init')
			//activateAnimation(meditation1Input);
			//goToMeditation1(riveInstance);
		},
		/*
        onStop: (event) => {
            //console.log('Animation stopped', event);
            //console.log(event.data[0])
            if (event.data[0] === 'go_to_meditation_1') {
                playMeditation1Animation();
            }
        }*/
	});

	const meditation1Input = useStateMachineInput(rive, 'State Machine 1', 'meditation1');
	const meditation2Input = useStateMachineInput(rive, 'State Machine 1', 'meditation2');
	const boostInput = useStateMachineInput(rive, 'State Machine 1', 'boost');
	const tapInput = useStateMachineInput(rive, 'State Machine 1', 'tap'); // trigger -> .fire()

	useEffect(() => {
		activateAnimation(meditation1Input);
	}, [meditation1Input, meditation2Input]);

	useEffect(() => {
		//console.log(heroState);
		if (heroState == 'claim_now' || heroState == 'meditation2') {
			activateAnimation(meditation2Input);
		} else if (heroState == 'default') {
			setInputFalse(meditation1Input);
			setInputFalse(meditation2Input);
			setInputFalse(boostInput);
		} else if (heroState == 'meditation1' || heroState == 'cool_down') {
			activateAnimation(meditation1Input);
		} else {
			//    activateAnimation(meditation1Input);
		}
	}, [heroState]);

	//if (rive)
	//  console.log(rive)

	const activateAnimation = (input) => {
		setInputFalse(meditation1Input);
		setInputFalse(meditation2Input);
		setInputFalse(boostInput);
		if (input) {
			input.value = true;
		}
	};
	const setInputFalse = (input) => {
		if (input) {
			input.value = false;
		}
	};

	const getClassName = () => {
		if (heroBackground == 'gray') return 'main__image_gray';
		else return 'main__image_yellow';
	};

	return (
		<div className={`main__image animate__animated animate__fadeIn  ${getClassName()}`}>
			<RiveComponent />
		</div>
	);
};

export default HeroComponent;
