import React, { useState, useEffect } from 'react';
import { usePopup } from '../../../Popups/PopupContext.js';
import Icons from '../../../Common/IconsComponent.jsx';
import CountdownTimer from './CountdownTimer.jsx';
import { useClaimerMutation } from '../../../../services/phpService';

const ClaimerWidget = () => {
	const {
		showPopup,
		hidePopup,
		heroState,
		setHeroState,
		setHeroBackground,
		setUserBalance,
		user,
		setUser,
		setIsClaimerReady,
		isClaimerReady,
		genHash,
		addNotification,
	} = usePopup();
	const [claimerTime, setClaimerTime] = useState(0);
	const [claimerBonus, setClaimerBonus] = useState(1);
	const [isClaimerLoading, setClaimerLoading] = useState(false);
	const [shitIconClassName, setshitIconClassName] = useState('');
	const [claimer] = useClaimerMutation();

	function secondsToFormattedTime(seconds) {
		const years = Math.floor(seconds / (365 * 24 * 60 * 60)); // Approximate number of years
		seconds %= 365 * 24 * 60 * 60;

		const months = Math.floor(seconds / (30 * 24 * 60 * 60)); // Approximate number of months
		seconds %= 30 * 24 * 60 * 60;

		const days = Math.floor(seconds / (24 * 60 * 60)); // Number of days
		seconds %= 24 * 60 * 60;

		const hours = Math.floor(seconds / 3600); // Number of hours
		seconds %= 3600;

		const minutes = Math.floor(seconds / 60); // Number of minutes
		const remainingSeconds = seconds % 60; // Remaining seconds

		//return `${years} years, ${months} months, ${days} days, ${hours} hours, ${minutes} minutes, ${remainingSeconds} seconds`;
		return `${hours} hours, ${minutes} minutes, ${remainingSeconds} seconds -- `;
	}

	useEffect(() => {
		if (user) {
			const currentTime = Math.floor(Date.now() / 1000);

			//console.log(secondsToFormattedTime(currentTime), secondsToFormattedTime(user.claimer_timer));

			if (user.claimer_timer === 0) {
				setClaimerBonus(1);
				setIsClaimerReady(true);
				setHeroState('claim_now');
				setHeroBackground('yellow');
			} else if (currentTime > user.claimer_timer) {
				setIsClaimerReady(true);
			} else {
				setHeroBackground('gray');
				setIsClaimerReady(false);
				setClaimerTime(user.claimer_timer);
			}
		}
	}, []);

	const handleClaim = async () => {
		//console.log('handleClaim called');
		//const newTime = new Date(new Date().getTime() + 10 * 1000);
		setClaimerLoading(true);
		try {
			const res = await claimer({
				token: await genHash(),
				wallet_address: user?.wallet_address,
				id_telegram: user?.id_telegram,
			}).unwrap();

			setClaimerLoading(false);
			if (res.success) {
				setClaimerTime(res.claimer_timer);
				setClaimerBonus(res.claimer_bonus);
				setshitIconClassName('animate__animated animate__fadeOutUp');
				const updatedUser = {
					...user,
					claimer_timer: res.claimer_timer,
					wallet_balance: res.wallet_balance,
				};
				setUser(updatedUser);

				setHeroState('default');
				setIsClaimerReady(false);

				// animations for icon
				setTimeout(function () {
					setshitIconClassName('');
				}, 500);
			} else {
			}
		} catch (e) {
			console.log(e);
			setClaimerLoading(false);

			if (e?.data?.error_code == 'claimer_not_ready') {
				setClaimerTime(e?.data?.claimer_timer);
				setHeroBackground('gray');
				setHeroState('cool_down');
				setIsClaimerReady(false);
			}
			if (e?.data?.message) addNotification('error', 'oops... Please try again later.');
			//  openModal('red', `${t('modalError')}`, `${t('modalReturn')}`);
		}
	};

	const handleDailyTasks = () => {
		// alert('Feature in developent mode');
		console.log('handleDailyTasks called');
		addNotification('info', 'Feature in developent mode...');
	};

	//const targetTime = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour later

	return (
		<div className='main__widget_section'>
			{isClaimerReady ? (
				<>
					{user.claimer_timer == 0 ? (
						<div className='main__text'>
							{' '}
							you made your
							<br /> first $hit{' '}
						</div>
					) : (
						<div className='main__text'> Claim your $hit </div>
					)}
				</>
			) : (
				<div className='main__text'>
					Next claim in <CountdownTimer targetTime={claimerTime} />
				</div>
			)}

			<button
				className={`main__btn  ${!isClaimerReady ? 'btn-inactive' : 'btn'} ${
					isClaimerLoading ? 'btn-loading' : ''
				} `}
				data-modal='connect-shit-wallet'
				onClick={handleClaim}
			>
				{isClaimerReady ? <> +{user?.claimer_value} $hit point!</> : <>cool down</>}
			</button>

			{/*
            
             <button className="main__btn btn-black" data-modal="connect-shit-wallet" onClick={handleDailyTasks}>
                    Daily Tasks
                   
                </button>*/}
		</div>
	);
};

export default ClaimerWidget;
