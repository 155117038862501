/**
 * Generates a referral link for a given Telegram user and optional project.
 *
 * @param {string} telegram_id - The Telegram user ID.
 * @param {string|null} project_id - The project ID (optional).
 * @returns {string} The generated referral link.
 */
export function getReferralLink(telegram_id, project_id = null) {
    let referralURL = `t.me/the_shit_money_bot/app?startapp=u${telegram_id}`;
    if (project_id) {
        referralURL += `p${project_id}`;
    }
    return referralURL;
}


/**
 * Generates a Telegram Share link with a referral URL.
 *
 * @param {string} telegram_id - The Telegram user ID.
 * @param {string|null} project_id - The project ID (optional).
 * @returns {string} The generated Telegram Share link.
 */
export const getTelegramShareLink = (telegram_id, project_id = null) => {
    let referralURL = getReferralLink(telegram_id, project_id);
    return `https://t.me/share/url?url=${encodeURIComponent(
        referralURL
    )}&text=${encodeURIComponent('Check out this referral link!')}`;
};