import React, { useState} from 'react';
import Icons from '../../../Common/IconsComponent';
import { usePopup } from '../../../Popups/PopupContext.js';
import TaskListItem from './TaskListItem';
const TaskList = () => {
	const {
		hidePopup,
		user
	} = usePopup();
	const referralURL = `t.me/the_shit_money_bot/app?startapp=${user.id_telegram}`;
	const [tasks] = useState([
		{
			name: 'follow tg channel',
			button_name: 'follow',
			link: 'https://t.me/the_shit_gen',
			code: 'tg_channel',
			icon: <Icons.ShitOutline />,
			bonus: 1,
		},
		{
			name: 'join the tg chat',
			button_name: 'join',
			link: 'https://t.me/the_shit_chat',
			code: 'tg_chat',
			icon: <Icons.ShitOutline />,
			bonus: 1,
		},
		{
			name: 'follow twitter',
			button_name: 'follow',
			link: 'https://x.com/earntheshit',
			code: 'twitter',
			icon: <Icons.ShitOutline />,
			bonus: 1,
		},
		{
			name: 'connect wallet',
			button_name: 'follow',
			link: '',
			code: 'is_wallet_connected',
			icon: <Icons.ShitOutline />,
			bonus: 1,
		},
		{
			name: 'invite fren',
			button_name: 'follow',
			link: `https://t.me/share/url?url=${encodeURIComponent(referralURL)}
			&text=${encodeURIComponent('Check out this referral link!')}`,
			code: 'referrals_count',
			icon: <Icons.ShitOutline />,
			bonus: 1,
		},
	]);

	const сloseModal = () => {
		hidePopup();
	};

	return (
		<div className='main__tasks tasks'>
			<div className='modal__close' onClick={сloseModal}>
							<Icons.Close />
						</div>
			<ul className='tasks__list'>
				{tasks.map((task, index) => (
					<li key={index} className='tasks__item'>
						<TaskListItem task={task} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default TaskList;
