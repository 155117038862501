import React, { useState, useEffect } from 'react';
import { usePopup } from '../../../Popups/PopupContext.js';

const CountdownTimer = ({ targetTime }) => {
	const { setIsClaimerReady, setHeroState, setHeroBackground } = usePopup();

	const formatTime = (time) => {
		return time < 10 ? `0${time}` : time;
	};

	const calculateTimeLeft = () => {
		const currentTime = Math.floor(Date.now() / 1000);
		const difference = targetTime - currentTime - 1;
		let timeLeft = {}; 

		if (difference >= 1) {
			timeLeft = {
				hours: Math.floor((difference / 60 / 60) % 60),
				minutes: Math.floor((difference / 60) % 60),
				seconds: Math.floor(difference % 60),
			};
		} else if (targetTime !== 0) {
			setHeroState('claim_now');
			setHeroBackground('yellow');
			setIsClaimerReady(true);
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		setTimeLeft(calculateTimeLeft());
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [targetTime]);

	return (
		<span className='main__text'>
			{timeLeft.hours > 0 ? `${formatTime(timeLeft.hours)}:` : ''}
			{timeLeft.minutes > 0 ? `${formatTime(timeLeft.minutes)}:` : ''}
			{formatTime(timeLeft.seconds)}
		</span>
	);
};

export default CountdownTimer;
