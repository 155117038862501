import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import {
} from 'react-router-dom';
import bcrypt from 'bcryptjs';
import useNotifications from '../Notification/useNotifications';
import { useTonConnectUI, useTonAddress, toUserFriendlyAddress} from '@tonconnect/ui-react';
import {useSetWalletMutation, useChangeWalletMutation, useDisconnectWalletMutation, useGetUserByTgIdQuery } from '../../services/phpService';

const PopupContext = createContext();
export const usePopup = () => useContext(PopupContext);


export const PopupProvider = ({ children }) => {
    /* User */
    const [userTelegramId, setUserTelegramId] = useState(null);
    const [user, setUser] = useState(null);
    const [heroState, setHeroState] = useState('default');
    const [heroBackground, setHeroBackground] = useState('yellow');
    const [userBalance, setUserBalance] = useState(0);
    const [isUserDataPollingDisabled, setUserDataPollingDisabled] = useState(true);
    /*
    const tg = window.Telegram.WebApp;
	const initData = tg.initDataUnsafe;
	const userId = initData?.user?.id;
    const [skipUserFetch, setSkipUserFetch] = useState(false);
    const { data: user } = useGetUserByTgIdQuery(userId, {
		skip: skipUserFetch,
		pollingInterval: 1500,
	});

    useEffect(() => {
		console.log('checking user On change')
		console.log(user)
		if (user) {
			setSkipUserFetch(false);
			login();
			
			if (user?.wallet_address)
				connect_wallet()
		}
		
	}, [user]);
    */
    const storageUserID = localStorage.getItem('userID');
    const userId = storageUserID ? Number(storageUserID) : null;
    const { data: userData, isLoading: userDataLoading } = useGetUserByTgIdQuery(userId, {
        skip: isUserDataPollingDisabled,
        pollingInterval: 10000,
    });
    useEffect(() => {
        if (userData?.id_telegram)
        {
            setUser(userData);
            login();
        }
        console.log(storageUserID)
    },[userData])


    /* Auth */
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const login = () => setIsAuthenticated(true);
    const logout = () => setIsAuthenticated(false);

    /* Wallet */
    const [isConectedWallet, setIsConectedWallet] = useState(false);
    const [isSkipSyncWallet, setIsSkipSyncWallet] = useState(false);
    const connect_wallet = () => setIsConectedWallet(true);
    const disconnect_wallet = () => setIsConectedWallet(false);
	const [tonConnectUI] = useTonConnectUI();
	const ton_address = useTonAddress(true);
    const [setWallet] = useSetWalletMutation();
    const [changeWallet] = useChangeWalletMutation();
    const [disconnectWallet] = useDisconnectWalletMutation();
	

    /*
    useEffect(() => {
        console.log('TON ui address changed');

        const handleWalletStatusChange = async () => {
            if (!user) return;

            console.log('ton address', ton_address)
            console.log('user wallet',user?.wallet_address)
            if (!ton_address || ton_address === null || ton_address == ''){
				if (user?.wallet_address)
				{
					console.log('disconnect wallet');
                    updateDisconnectWallet(user.wallet_address);
                    const updatedUser = {
                        ...user,
                        wallet_address: null,
                    };
                    setUser(updatedUser);
				}
			}
			else {
				if (!user?.wallet_address) {
                    console.log('submit wallet');
					await updateWallet();
                    const updatedUser = {
                        ...user,
                        wallet_address: ton_address,
                    };
                    setUser(updatedUser);
				} else if (
					user?.wallet_address &&
					ton_address !== user?.wallet_address
				) {
                    console.log('update wallet');
					await updateWallet();
                    const updatedUser = {
                        ...user,
                        wallet_address: ton_address,
                    };
                    setUser(updatedUser);
				}
			}
        }
        handleWalletStatusChange();
	},[ton_address])*/
    const userRef = useRef(user);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        const handleWalletStatusChange = async (wallet) => {
            const currentUser = userRef.current;
            if (!currentUser) return;
            console.log('is connected:', tonConnectUI.connected);
            
            if (tonConnectUI.connected)
            {
                const currentAddress = toUserFriendlyAddress(wallet?.account?.address)
                if (currentAddress && currentAddress != currentUser?.wallet_address)
                {
                    console.log('updating user wallet: ', currentAddress, currentUser);
                    let update_result = await updateWallet(currentUser?.id_telegram, currentAddress);
                    if (update_result)
                    {
                        setIsSkipSyncWallet(true)
                        const updatedUser = {
                            ...currentUser,
                            wallet_address: currentAddress,
                            is_wallet_connected: 1
                        };
                        setUser(updatedUser);
                    }
                }
            }
            else {
                console.log('disconnect wallet');
                await updateDisconnectWallet(currentUser.wallet_address);
                setIsSkipSyncWallet(true)
                const updatedUser = {
                    ...currentUser,
                    wallet_address: null,
                };
                setUser(updatedUser);
            }
        }

        tonConnectUI.onStatusChange(handleWalletStatusChange);
    }, []);

    const submitWallet = async () => {
        if (user?.id_telegram)
        {
            try {
                const res = await setWallet({
                    token: await genHash(),
                    wallet_address: ton_address,
                    id_telegram: user?.id_telegram,
                }).unwrap();
                console.log('saving wallet');
            } catch (e) {
                console.error('Error connecting wallet:', e);
                if (e?.data?.error_code && e?.data?.error_code == 'wallet_exist')
                {
                    tonConnectUI.disconnect();
                    addNotification(
                        'error',
                        'This wallet is already connected to other account. Please use another wallet.'
                    );
                }
            }
        }
	};

    const updateWallet = async (id_telegram, ton_address) => {
        console.log('user_id'+id_telegram)
		if (ton_address) {
			try {
				const res = await changeWallet({
					token: await genHash(),
					wallet_address: ton_address,
					id_telegram: id_telegram,
				}).unwrap();
                return res;
			} catch (e) {
                console.error('Error connecting wallet:', e);
                //if (e?.data?.error_code && e?.data?.error_code == 'wallet_exist')
                if (e?.data?.wallet_address == 'The wallet address has already been taken.')
                {
                    tonConnectUI.disconnect();
                    addNotification(
                        'error',
                        'This wallet is already connected to other account. Please use another wallet.'
                    );
                }
                return false;
			}
		}
	};

    const updateDisconnectWallet = async (ton_address) => {
        if (ton_address) {
            try {
                const res = await disconnectWallet({
                    token: await genHash(),
                    wallet_address: ton_address
                }).unwrap();
                console.log('wallet disconnected')
            } catch (e) {
                console.log(e);
            }
        }
	};

    

    useEffect(() => {
        const syncTonWallet = () => {
            if (!user || !tonConnectUI.connected) return;
        
            if (isSkipSyncWallet)
                {
                    setIsSkipSyncWallet(false)
                    return;
                }

            console.log('syncing wallets ton:'+ton_address+', users:'+user?.wallet_address)
            const currentUser = userRef.current;

            if (
                (user?.wallet_address &&
                ton_address !== user?.wallet_address) ||
                (!user?.wallet_address && ton_address)
            )
            {
                console.log('wallets notequal, disconnecting')
                tonConnectUI.disconnect();
            }
            
        }
        //console.log('users wallet changed to: ', user?.wallet_address)
        syncTonWallet();
    },[user?.wallet_address])


    /* Tasks */
    const [isTasksCompleted, setIsTasksCompleted] = useState(false);
    const complete_tasks = () => setIsTasksCompleted(true);
    const [isTasksTGGroup, setisTasksTGGroup] = useState(false);
    const complete_task_tg_group = () => setisTasksTGGroup(true);
    const [isTasksTGChannel, setisTasksTGChannel] = useState(false);
    const complete_task_tg_channel = () => setisTasksTGChannel(true);
    const [isTasksTwitter, setisTasksTwitter] = useState(false);
    const complete_task_twitter = () => isTasksTwitter(true);

    /* Navigation */
    /*
	const navigate = useNavigate();
    const location = useLocation();
	useEffect(() => {
        if (!isAuthenticated)
            navigate('/');
        else if (!isConectedWallet)
			navigate('/home/connect_wallet');
		else if(isTasksCompleted)
			navigate('/home/tasks');
		else 
			navigate('/home');
    }, [location.pathname]); */

    /* Claimer */
    const [isClaimerReady, setIsClaimerReady] = useState(false);

    /* Popus */
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const { notifications, addNotification, removeNotification } = useNotifications();

    /* Profile */
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const showProfile = () => {
        setIsProfileOpen(true);
    }

    const hideProfile = () => {
        setIsProfileOpen(false);
    }


    const showPopup = (content) => {
        setPopupContent(content);
        setIsPopupOpen(true);
    };

    const hidePopup = () => {
        setIsPopupOpen(false);
        setPopupContent(null);
    };

    /* Task Popus */
    const [isTaskPopupOpen, setIsTaskPopupOpen] = useState(false);
    const [popupTaskContent, setPopupTaskContent] = useState(null);

    const showTaskPopup = (content) => {
        setPopupTaskContent(content);
        setIsTaskPopupOpen(true);
    };

    const hideTaskPopup = () => {
        setIsTaskPopupOpen(false);
        setPopupTaskContent(null);
    };

    /* Helpers */
    const genHash = async () => {
		const options = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
			timeZone: 'UTC',
		};
		const secretKey = process.env.REACT_APP_SECRET_KEY;
		const now = new Date();
		const dateStringWithTime = now.toLocaleString('en-GB', options);	
		const date =  dateStringWithTime; //'26/07/2024, 19:48';
		const crypt = await bcrypt.hash(secretKey + date, 10)
		return crypt;
	};

    // State to store the current window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [contentWidth, setContentWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        
        <PopupContext.Provider value={{ 
            isPopupOpen, popupContent, showPopup, hidePopup, 
            isTaskPopupOpen, popupTaskContent, showTaskPopup, hideTaskPopup, 
            heroState, 
            setHeroState, 
            heroBackground, setHeroBackground,
            userBalance, 
            setUserBalance, 
            isAuthenticated, login, logout,
            isConectedWallet, connect_wallet, disconnect_wallet,
            isTasksCompleted, complete_tasks,
            isTasksTGGroup,complete_task_tg_group,
            isTasksTGChannel, complete_task_tg_channel,
            isTasksTwitter, complete_task_twitter,
            userTelegramId, setUserTelegramId,
            user, setUser,
            genHash,
            isClaimerReady, setIsClaimerReady,
            addNotification, notifications, removeNotification,
            isProfileOpen, showProfile, hideProfile,
            windowWidth, contentWidth,
            setUserDataPollingDisabled, isUserDataPollingDisabled
            }}>
            {children}
        </PopupContext.Provider>
    );
};
