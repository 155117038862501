import React, { useState, useEffect, useRef } from 'react';
import ContentLoader from 'react-content-loader';
import Icons from '../../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../../Popups/PopupContext.js';
import { useGetProjLeadersMutation } from '../../../../../services/phpService';
import './ClickerLeaders.scss';

const ClickerLeaders = ({ projectId }) => {
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [getProjLeaders] = useGetProjLeadersMutation();
	const { user, hidePopup } = usePopup();
	const containerRef = useRef(null);
	const [containerWidth, setContainerWidth] = useState(0);

	const userId = user?.id_telegram;
	const projId = projectId;

	useEffect(() => {
		if (user) {
			fetchData();
			console.log(userId, projId);
		}
	}, [user]);

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const loadTimeStart = Date.now();
			const res = await getProjLeaders({ projId, userId }); // Corrected: pass as an object

			if (res?.data) {
				setLeaderboardData(res.data);
			}

			const loadTimeEnd = Date.now();
			const elapsedTime = loadTimeEnd - loadTimeStart;
			const remainingTime = 500 - elapsedTime;

			if (remainingTime > 0) {
				setTimeout(() => setIsLoading(false), remainingTime);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Error fetching leaderboard data:', error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	const numberOfRects = 12;
	const rects = Array.from({ length: numberOfRects }, (_, i) => (
		<rect key={i} x='0' y={i * 58} rx='16' ry='16' width={containerWidth} height='52' />
	));

	const closeModal = () => {
		hidePopup();
	};

	return (
		<div className='ratings'>
			<div className='ratings__container container'>
				<div className='ratings__body' ref={containerRef}>
					<div className='ratings__close' onClick={closeModal}>
						<Icons.Close />
					</div>
					<h1 className='ratings__title'>Leaderboard</h1>
					{isLoading ? (
						<ContentLoader
							speed={2}
							width={'100%'}
							height={'100%'}
							viewBox={`0 0 ${containerWidth} 700`}
							backgroundColor='#f3f3f3'
							foregroundColor='#ecebeb'
						>
							{rects}
						</ContentLoader>
					) : (
						<ul className='ratings__list'>
							{leaderboardData.map((player, index) => {
								const isCurrentUser = player.username === user?.username;
								const isTopThree = index < 3;
								return (
									<li
										className={`ratings__listItem ${
											isCurrentUser && !isTopThree ? 'highlight' : ''
										}`}
										key={index}
									>
										<div className='ratings__itemData'>
											<div className='ratings__listId'>
												<Icons.PlayerId />
												<span>{player.position}</span>
											</div>
											<div className='ratings__listName'>
												<span>
													{player.username?.length > 10
														? `${player.username.substring(0, 10)}...`
														: player.username ?? 'anonymous'}
												</span>
											</div>
											<div className='ratings__listBalance'>
												<span>{player.total_votes}</span>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default ClickerLeaders;
