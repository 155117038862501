import React, { useEffect, useState } from 'react';

const ErrorhScreen = () => {
	return (
		<div className='error_404'>
			<img className='img_404' src='/404.svg' alt='404' />
			<img className='img_404_face' src='/404_face.svg' alt='face' />
			<p className='p_not_found'>not found</p>
			<div className='d_404_desc'>
				We’re sorry, the page you requested cloud was not found.
			</div>
			<div className='projects__btn btn'>back</div>
		</div>
	);
};
export default ErrorhScreen;
