import { useEffect, useState, useRef } from 'react';
import React from 'react';
import NavigationWidget from '../NavigationWidget';
import ContentLoader from 'react-content-loader';
import { useGetProjectsMutation } from '../../../../services/phpService';
import Icons from '../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../Popups/PopupContext';
import { useNavigate } from 'react-router-dom';

export default function ProjectsScreen() {
	const [getProjects] = useGetProjectsMutation();
	const [topProjectsData, setTopProjectsData] = useState([]);
	const [pulseProjectsData, setPulseProjectsData] = useState([]);
	const [activeTab, setActiveTab] = useState('topProjectsData');
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const { user } = usePopup();
	const baseURL = process.env.REACT_APP_SECRET_IMG;
	const [containerWidth, setContainerWidth] = useState(0);
	const containerRef = useRef(null);

	useEffect(() => {
		if (!user) navigate('/?r=projects');
	}, []);

	useEffect(() => {

		//const localStorage.getItem('projectsFetchTime'))

		const fetchAllProjects = async () => {
			setIsLoading(true);
			try {
				const res = await getProjects();
				if (res?.data) {
					filterProjects(res.data);
					localStorage.setItem('projectsData', JSON.stringify(res.data));
					//localStorage.setItem('projectsFetchTime', Math.floor(Date.now() / 1000));
				}
			} catch (error) {
				console.error('Error fetching projects data:', error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchAllProjects();
	}, []);

	const filterProjects = (data) => {
		const pulseProjects = data.filter((project) => project.pulsechain === 1);
		const topProjects = data
			.filter((project) => project.pulsechain !== 1)
			.sort((a, b) => b.vote_total - a.vote_total);

		setPulseProjectsData(pulseProjects);
		setTopProjectsData(topProjects);
	};

	const handleTabChange = (tab) => {
		setActiveTab(tab);
		const savedProjectsData = localStorage.getItem('projectsData');
		if (savedProjectsData) {
			filterProjects(JSON.parse(savedProjectsData));
		}
	};

	const handleProjectClick = (project) => {
		navigate(`/projects/${project.id}`);
	};

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	const numberOfRects = 12;
	const rects = Array.from({ length: numberOfRects }, (_, i) => (
		<rect key={i} x='0' y={i * 51} rx='16' ry='16' width={containerWidth} height='45' />
	));

	const renderTabContent = (data) => {
		return (
			<ul className='ratings__list'>
				{data.map((project, index) => (
					<li
						className={`ratings__item ${
							project.has_game === 1 ? 'ratings__highlighted' : ''
						}`}
						key={index}
						onClick={() => handleProjectClick(project)}
					>
						<div className='ratings__image'>
							{project.has_game === 1 && (
								<div
									style={{
										position: 'absolute',
										display: 'flex',
										alignItems: 'center',
										zIndex: 10,
									}}
								>
									<Icons.ProjEvent />
								</div>
							)}
							<img src={`${baseURL}${project.image}`} alt={project.name} />
						</div>
						<div className='ratings__name'>{project.name}</div>
						<div className='ratings__value'>
							{project.vote_total}
							<Icons.Shit />
						</div>
					</li>
				))}
			</ul>
		);
	};

	return (
		<>
			<div className='ratings'>
				<div className='ratings__container container'>
					<div className='ratings__body' ref={containerRef}>
						<h1 className='ratings__title'>The Best $hit</h1>
						<div className='ratings__links'>
							<button
								className={`tab-btn ratings__btn btn ${
									activeTab === 'topProjectsData' ? 'active' : ''
								}`}
								onClick={() => handleTabChange('topProjectsData')}
							>
								Top
							</button>
							{pulseProjectsData.length > 0 && (
								<button
									className={`tab-btn ratings__btn btn ${
										activeTab === 'pulseProjects' ? 'active' : ''
									}`}
									onClick={() => handleTabChange('pulseProjects')}
								>
									Pulse Chain
								</button>
							)}
						</div>
						{isLoading ? (
							<ContentLoader
								speed={1}
								width='100%'
								height='100%'
								viewBox={`0 0 ${containerWidth} 600`}
								backgroundColor='#f3f3f3'
								foregroundColor='#ecebeb'
							>
								{rects}
							</ContentLoader>
						) : (
							<>
								{activeTab === 'topProjectsData' && renderTabContent(topProjectsData)}
								{activeTab === 'pulseProjects' && renderTabContent(pulseProjectsData)}
							</>
						)}
					</div>
				</div>
			</div>
			<NavigationWidget />
		</>
	);
}
