import React, { useState, useEffect } from 'react';
import Icons from '../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../Popups/PopupContext.js';
import {
	useCheckTaskMutation,
	useSetWalletMutation,
} from '../../../../services/phpService';
import {
	TonConnectButton,
	useTonWallet,
	useTonConnectUI,
	useTonAddress,
} from '@tonconnect/ui-react';
import useSaveAction from '../../../../helpers/analyticsHelper';

const TaskListItem = ({ task }) => {
	const {
		isTaskPopupOpen,
		popupTaskContent,
		hideTaskPopup,
		user,
		setUser,
		genHash,
		addNotification,
	} = usePopup();
	const [isClicked, setIsClicked] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const [timerStarted, setTimerStarted] = useState(false);
	const [timerFinished, setTimerFinished] = useState(false);
	const [checkTasks] = useCheckTaskMutation();
	const waitingPeriod = 8000;
	const [tonConnectUI] = useTonConnectUI();
	const { saveAction } = useSaveAction();

	useEffect(() => {
		let timer;
		if (timerStarted) {
			setTimerFinished(false);
			timer = setTimeout(() => {
				setTimerStarted(false);
				setTimerFinished(true);
				clearTimeout(timer);
			}, waitingPeriod);
		}
	}, [timerStarted]);

	const openLink = (link, code) => {
		
		saveAction(user?.id_telegram, 'click_main_task_item', user?.parent_id)

		if (code == 'twitter') {
			// starting timer only for twitter
			setTimerStarted(true);
		}

		if (code == 'is_wallet_connected') {
			tonConnectUI.openModal();
			return;
		}

		if (code == 'referrals_count') {
			window.open(link, '_blank');
			return;
		}

		setIsClicked(true);
		window.open(link, '_blank');
	};

	const checkTask = async (code) => {
		if (code == 'twitter' && timerFinished === false) {
			addNotification('error', 'Task is not completed. Please try again.');
			return;
		}

		try {
			const res = await checkTasks({
				token: await genHash(),
				wallet_address: user?.wallet_address,
				id_telegram: user?.id_telegram,
				code: code,
			}).unwrap();

			if (res.success) {
				setIsCompleted(true);
				setTimerFinished(false);
				const updatedUser = { ...user, [code]: 1, wallet_balance: res?.user_balance };
				setUser(updatedUser);
			} else {
			}
		} catch (e) {
			console.log(e);
			if (e?.data?.message);
			addNotification('error', 'Oops... Please try again later.');
		}
	};

	useEffect(() => {
		if (user[task.code]) setIsCompleted(true);
	}, [user]);

	return (
		<a
			href='#'
			className='tasks__link'
			onClick={(e) => {
				e.preventDefault();
			}}
		>
			<div className='tasks__icon'>{task.icon}</div>

			<div className='tasks__name'>{task.name}</div>

			{isCompleted ? (
				<div className='tasks__value tasks__value_done'>done</div>
			) : isClicked ? (
				<div
					onClick={() => checkTask(task?.code)}
					className='tasks__value tasks__value_check'
				>
					check
				</div>
			) : (
				<div onClick={() => openLink(task?.link, task?.code)} className='tasks__value'>
					+{task.bonus} <Icons.ShitIcon />
				</div>
			)}
		</a>
	);
};

export default TaskListItem;
