import React, { useState } from 'react';
import { usePopup } from './PopupContext';
import Icons from '../Common/IconsComponent';
import ClickerLeaders from '../Screens/Home/Projects/Project/ClickerLeaders';

const Popup = () => {
	const { isPopupOpen, popupContent, hidePopup } = usePopup();
	const [showForm, setShowForm] = useState(false);

	const handleSP = () => {
		setShowForm((prevShowForm) => !prevShowForm);
	};

	const closeModal = () => {
		hidePopup();
	};

	const isClickerLeadersContent =
		React.isValidElement(popupContent) && popupContent.type === ClickerLeaders;

	// Conditional styles for modal__wrapper
	const wrapperStyle = {
		position: isClickerLeadersContent ? 'static' : 'absolute', // Toggle position based on condition
	};

	return (
		<div onClick={hidePopup}>
			<div className={`modal ${isPopupOpen ? '_active' : ''}`}>
				<div
					className='modal__wrapper'
					style={wrapperStyle}
					onClick={(e) => e.stopPropagation()}
				>
					<div
						className={`modal__content ${
							isClickerLeadersContent ? 'white_bg' : 'black_bg'
						}`}
					>
						{popupContent}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Popup;
