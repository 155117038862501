import React from 'react';
import QRimg from '../../../assets/img/qr.png';
import shitLogo from '../../../assets/img/main/image.png';
import './QRscreen.scss'; // Import the CSS file for styling

const QRscreen = () => {
	return (
		<div
			className='qrComponent'
			style={{
				background: `#fff500`,
				height: 'calc(100% + 75px)',
			}}
		>
			<div
				style={{
					display: 'flex',
					scale: '100%',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<img
					src={shitLogo}
					alt='shit logo'
					style={{
						width: '80px',
						borderRadius: '20px',
					}}
				/>
			</div>
			<div className='qrComponentText'>
				<h4>
					{' '}
					Leave the desktop. <br />
					Mobile is the $hit!
				</h4>
			</div>
			<div
				style={{
					display: 'flex',
					scale: '100%',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<img
					src={QRimg}
					alt='QR redirect'
					style={{
						width: '300px',
						borderRadius: '20px',
					}}
				/>
			</div>
		</div>
	);
};

export default QRscreen;
