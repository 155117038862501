import React, { useEffect, useState } from 'react';
import Icons from '../../Common/IconsComponent';
import { usePopup } from '../../Popups/PopupContext.js';

const BalanceComponent = ({ visible, title }) => {
	const { userBalance, user } = usePopup();
	const [balance, setBalance] = useState(0);
	const [className, setClassName] = useState('main__value');

	useEffect(() => {
		if (user?.wallet_balance && user.wallet_balance != balance) {
			setBalance(user.wallet_balance);
			// if (userBalance!=0) {
			setClassName('main__value animate__animated animate__tada');
			setTimeout(function () {
				setClassName('main__value');
			}, 2000);
			//}
		}
	}, [user]);

	//if (!visible) return  null;

	return (
		<div className='balance__wrapper'>
			{visible && (
				<>
					<div className='main__text'>{title}</div>
					<div className={className}>
						{balance}
						<Icons.Shit />
					</div>
				</>
			)}
		</div>
	);
};
export default BalanceComponent;
