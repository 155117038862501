import React, { useState, useEffect, useRef } from 'react';
import durovImg from '../../../../../assets/img/projects/durov.webp';
import Icons from '../../../../Common/IconsComponent';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopup } from '../../../../Popups/PopupContext';
import { useUpdateProjBalanceMutation } from '../../../../../services/phpService';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import loadingImg from '../../../../../assets/img/loading.gif';
// import paperPlane from '../../../../../assets/img/paperPlane.webp';
import ProjectTasks from './ProjectTasks';
// import EndSession from './Popup/EndSession';
import NavigationWidget from '../../NavigationWidget';
import ClickerLeaders from './ClickerLeaders';
import './Project.scss';

export default function ProjectClicker() {
	const { genHash, addNotification, user, showPopup, setUserDataPollingDisabled } =
		usePopup();
	const [updateProjBalance] = useUpdateProjBalanceMutation();
	const [currPoints, setCurrPoints] = useState(0);
	const [gamePaused, setGamePaused] = useState(false);
	const [timeRemaining, setTimeRemaining] = useState(10);
	const [resetPointsCalled, setResetPointsCalled] = useState(false);
	const [isPointsChanged, setIsPointsChanged] = useState(false);
	const [unsubmittedPoints, setUnsubmittedPoints] = useState(0);
	const [animations, setAnimations] = useState([]);
	const [isAnimationActive, setIsAnimationActive] = useState(false);
	const isPointsChangedRef = useRef(isPointsChanged);
	const timeoutRef = useRef(null);
	const accumulatedPointsRef = useRef(0);
	const [totalPoints, setTotalPoints] = useState(0);
	const [activeTasksCount, setActiveTasksCount] = useState(0);
	const [canPlayAt, setCanPlayAt] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const [webpImage, setWebpImage] = useState(null);
	const { projectId: projectIdParam } = useParams();
	const navigate = useNavigate();

	const projectId = Number(projectIdParam);

	let [pointsVal, setPointsVal] = useState(1);
	let [clickNewPoints, setClickNewPoints] = useState(1);
	const secretURL = process.env.REACT_APP_SECRET_URL;

	const navBack = () => {
		navigate(`/projects/${projectId}`);
	};

	useEffect(() => {
		if (!user) {
			setTotalPoints(0);
			navigate('/');
			return;
		} else setUserDataPollingDisabled(false);
	}, [user]);

	useEffect(() => {
		if (user?.projects_gaming) {
			const matchingProject = user.projects_gaming.find(
				(project) => Number(project.project_id) === projectId
			);

			if (matchingProject) {
				setTotalPoints(matchingProject.votes);
				setCanPlayAt(matchingProject.can_play_at);

				setTimeout(() => {
					setCurrPoints(matchingProject.energy);
				}, 500);
			}
		}
	}, [user, projectId]);

	useEffect(() => {
		const storedProjects = localStorage.getItem('projectsData');

		if (storedProjects) {
			try {
				const parsedProjects = JSON.parse(storedProjects);
				if (Array.isArray(parsedProjects)) {
					const project = parsedProjects.find((p) => p.id === projectId);

					if (project) {
						setWebpImage(project.webpImage || null);
						console.log(webpImage);
						setActiveTasksCount(
							Array.isArray(project.active_tasks) ? project.active_tasks.length : 0
						);
					}
				}
			} catch (error) {
				console.error('Error parsing JSON:', error);
			}
		} else {
			console.log('No data found in localStorage for key: projectsData');
		}
	}, [projectId]);

	const handleMakeClick = (id) => {
		if (activeTasksCount > 0) {
			showPopup(<ProjectTasks projectId={projectId} />);
		} else {
			addNotification('info', 'There are no tasks available');
		}
	};

	const handleShowLeaders = () => {
		showPopup(<ClickerLeaders projectId={projectId} />);
	};

	const pauseGame = async () => {
		const currentTimeStamp = Math.floor(Date.now() / 1000);
		const futureTimestamp = currentTimeStamp + 60 * 60;

		fetch(secretURL + '/api/set-project-activity', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				id_project: projectId,
				timestamp: futureTimestamp,
			}),
		})
			.then((response) => {
				if (response.ok) {
				} else {
					console.log('Failed to pause game');
				}
			})
			.catch((e) => {
				console.log('Error pausing game');
			});
	};

	useEffect(() => {
		let pauseTimeoutId;

		if (currPoints >= 1000) {
			setGamePaused(true);
			addNotification('success', 'Good job! You just voted for free');
			pauseTimeoutId = setTimeout(() => {
				pauseGame();
			}, 1000);
		}
		clearTimeout(pauseTimeoutId);
	}, [currPoints]);

	// useEffect(() => {
	// 	if (gamePaused) {
	// 		showPopup(<EndSession canPlayAt={timeRemaining} />);
	// 	}
	// }, [gamePaused]);

	const getGameStatus = async () => {
		try {
			const response = await fetch(`${secretURL}/api/telegram-id/${user?.id_telegram}`);
			if (!response.ok) {
				throw new Error('Failed to fetch game status');
			}
			const initGameStatusCheck = await response.json();
		} catch (e) {
			console.log('Error fetching leaderboard data');
		}
	};

	useEffect(() => {
		if (user) {
			const updateGameStatus = () => {
				const currentTimeStamp = moment.tz('UTC').unix();
				const remainingTime = canPlayAt - currentTimeStamp;
				if (remainingTime >= 0) {
					if (remainingTime <= 0) {
						setGamePaused(false);
					} else {
						setGamePaused(true);
						setTimeRemaining(remainingTime);
					}
				}
			};

			getGameStatus();

			const timeout = setTimeout(() => {
				setIsLoading(false);
				getGameStatus();
			}, 1000);

			const timer = setInterval(() => {
				updateGameStatus();
			}, 1000);

			return () => {
				clearInterval(timer);
				clearTimeout(timeout);
			};
		}
	}, [canPlayAt]);

	useEffect(() => {
		if (currPoints <= 0) {
			setCurrPoints(0);
		}
	}, [currPoints]);

	const updateCurrPoints = () => {
		if (currPoints >= 500 && currPoints <= 550 && !resetPointsCalled) {
			setResetPointsCalled(true);
			resetPoints();
		}
		setIsPointsChanged(true);
		resetTimeout();

		return clickNewPoints;
	};

	const resetPoints = () => {
		const pointsToSubmit = accumulatedPointsRef.current + unsubmittedPoints;
		submitData(pointsToSubmit);
		accumulatedPointsRef.current = 0;
		setUnsubmittedPoints(0);
	};

	const resetTimeout = () => {
		setIsPointsChanged(false);
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			const pointsToSubmit = accumulatedPointsRef.current + unsubmittedPoints;
			submitData(pointsToSubmit);

			accumulatedPointsRef.current = 0;
			setUnsubmittedPoints(0);
		}, 1000);
	};

	useEffect(() => {
		isPointsChangedRef.current = isPointsChanged;
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [isPointsChanged]);

	const submitData = async (points) => {
		try {
			await updateProjBalance({
				token: await genHash(),
				id_telegram: user?.id_telegram,
				id_project: projectId,
				score: points,
			}).unwrap();
		} catch (e) {
			console.log('Error submitting points:', e);
			setUnsubmittedPoints((prev) => prev + points);
		}
	};

	const handleShowAnimation = (event) => {
		if (!event) return;

		const touch = event.touches ? event.touches[0] : event;
		const clicker = event.currentTarget || touch.target;
		if (!clicker) return;
		const x = touch.pageX;
		const y = touch.pageY;

		setAnimations((prev) => [...prev, { x, y }]);
		setIsAnimationActive(true);
	};

	const handleTouchStart = (event) => {
		if (event.touches) {
			Array.from(event.touches).forEach((touch) => {
				const clicker = event.currentTarget || touch.target;
				if (!clicker) return;
				const x = touch.pageX;
				const y = touch.pageY;
				handleShowAnimation({
					touches: [touch],
					target: event.target,
					currentTarget: event.currentTarget,
				});
			});
		} else {
			const clicker = event.currentTarget || event.target;
			if (!clicker) return;
			const x = event.pageX;
			const y = event.pageY;
			handleShowAnimation(event);
		}
	};

	const handleTouchEnd = () => {
		const clickNewPoints = updateCurrPoints();
		accumulatedPointsRef.current += clickNewPoints;
		setCurrPoints((prevPoints) => Math.min(prevPoints + pointsVal, 1000));
	};

	const clearAnimations = () => {
		setAnimations([]);
	};

	const calculateStrokeDasharray = (currPoints) => {
		const circleCircumference = 2 * Math.PI * 45; // 2 * PI * radius
		const percentage = (currPoints / 1000) * circleCircumference;
		return `${percentage} ${circleCircumference}`;
	};

	return (
		<>
			<div className='gameWrapper'>
				{isLoading ? (
					<img
						src={loadingImg}
						alt='Loading...'
						style={{
							width: '75px',
							height: '75px',
							position: 'absolute',
							top: '50%',
							bottom: '50%',
						}}
					/>
				) : (
					<>
						<div className='btn btn_icon btn_back' onClick={() => navBack()}>
							<Icons.Larrow />
						</div>
						<div
							className='btn btn_icon'
							style={{ position: 'absolute', top: '20px', right: '20px' }}
							onClick={handleShowLeaders}
						>
							<Icons.CupWhite />
						</div>
						<div className='clicker__votesTotal'>
							your votes:
							<div className='clicker__votesValue'>
								{totalPoints}
								<Icons.Shit />
							</div>
						</div>
						<div
							className='clicker__clickArea'
							onTouchStart={!gamePaused ? handleTouchStart : undefined} //!gamePaused
							onTouchEnd={
								!gamePaused ? (e) => handleTouchEnd(e.touches[0], e) : undefined //!gamePaused
							}
						>
							{animations.map((anim, index) => (
								<AnimatePresence key={index}>
									{isAnimationActive && (
										<motion.div
											className='clickerAnimation'
											initial={{ opacity: 1, y: 0 }}
											animate={{ opacity: [1, 0], y: [-150, -240] }}
											exit={{ opacity: 0 }}
											transition={{ duration: 1.5 }}
											style={{
												color: '#000',
												fontSize: '52px',
												left: `${anim.x}px`,
												top: `${anim.y}px`,
												position: 'absolute',
												color: '#333333',
												zIndex: 10,
											}}
											onAnimationComplete={() => {
												clearAnimations(index);
											}}
										>
											<div className='clicker__clickValue'></div>+{clickNewPoints}
										</motion.div>
									)}
								</AnimatePresence>
							))}
							<div className='clicker__clickerHero'>
								<picture>
									{projectId === 29 ? (
										<img src={durovImg} alt='Image' />
									) : (
										webpImage && <img src={webpImage} alt='Image' />
									)}
										{projectId !== 30 &&  (
  <>
    <div className='clicker__ripple'></div>
    <div className='clicker__ripple'></div>
    <div className='clicker__ripple'></div>
  </>
)}
								</picture>
							</div>
							<div className='clicker__progressBox'>
								<div className='clicker__progressBar'>
									<svg
										viewBox='0 0 100 100'
										style={{
											position: 'absolute',
											width: '100%',
											height: '100%',
											borderRadius: '100%',
										}}
									>
										{/* Define the filter for the box shadow */}
										<defs>
											<filter id='boxShadow' x='-20%' y='-20%' width='140%' height='140%'>
												<feGaussianBlur in='SourceAlpha' stdDeviation='3' />
												<feOffset dx='0' dy='0' result='offsetblur' />
												<feComponentTransfer>
													<feFuncA type='linear' slope='0.5' />
												</feComponentTransfer>
												<feMerge>
													<feMergeNode />
													<feMergeNode in='SourceGraphic' />
												</feMerge>
											</filter>
										</defs>

										{/* Circle with background */}
										<circle
											cx='50'
											cy='50'
											r='45'
											fill='none'
											stroke='#FFF500' // color of the progress bar background
											strokeWidth='1' // thickness of the progress bar
										></circle>

										{/* Circle with gradient */}
										<circle
											cx='50'
											cy='50'
											r='45'
											fill='none'
											stroke='url(#gradient)' // apply linear gradient
											strokeWidth='3' // thickness of the progress bar
											strokeLinecap='round'
											strokeDasharray={calculateStrokeDasharray(currPoints)}
											style={{ transition: 'stroke-dasharray 0.35s' }}
										></circle>

										{/* Define the linear gradient */}
										<defs>
											<linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
												<stop offset='0%' stopColor='#DE0046' />
												<stop offset='100%' stopColor='#F7A34B' />
											</linearGradient>
										</defs>
									</svg>
								</div>
							</div>
						</div>
						<div className='clicker__session'>
							<div className='clicker__sessionText'>
								{gamePaused ? (
									<>
										{timeRemaining ? (
											<>
												<Icons.clockIcon />
												<span style={{ marginLeft: '5px' }}>Next Tap session in:</span>
											</>
										) : (
											<span style={{ marginLeft: '5px' }}>
												Calculating time for next session...
											</span>
										)}
									</>
								) : (
									<span style={{ marginLeft: '5px' }}>Tap to vote:</span>
								)}
							</div>
							<div className='clicker__sessionProgress'>
								{gamePaused ? timeRemaining || null : `${currPoints}/1000`}
							</div>
						</div>
						{activeTasksCount > 0 && (
							<button
								onClick={() => handleMakeClick(projectId)}
								className='projects__btn btn-black'
							>
								tasks <span style={{ color: 'black' }}>{activeTasksCount}</span>
							</button>
						)}
					</>
				)}
			</div>
			<NavigationWidget />
		</>
	);
}
