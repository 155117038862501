import './App.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './assets/scss/style.scss';
import 'animate.css';
import {
	BrowserRouter,
	Route,
	Routes,
	createBrowserRouter,
	RouterProvider,
	useNavigate,
	useLocation,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import SplashScreen from './components/Screens/Splash/SplashScreen.jsx';
import HomeScreen from './components/Screens/Home/HomeScreen.jsx';
import ProjectsScreen from './components/Screens/Home/Projects/ProjectsScreen.jsx';
import RatingsScreen from './components/Screens/Home/Ratings/RatingsScreen.jsx';
import ErrorScreen from './components/Screens/ErrorScreen.jsx';
import ProfileScreen from './components/Screens/Home/Profile/ProfileScreen.jsx';
import ProjectsSlider from './components/Screens/Home/Projects/ProjectsSlider/ProjectsSlider';
import ProjectClicker from './components/Screens/Home/Projects/Project/ProjectClicker';
import { PopupProvider } from './components/Popups/PopupContext.js';
import Popup from './components/Popups/Popup.jsx';
import { store } from './services/store.js';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import NotificationsContainer from './components/Notification/NotificationsContainer.jsx';

const router = createBrowserRouter([
	{
		path: '/',
		element: <SplashScreen />,
	},
	/*
	{
		path: '/home',
		element: <ProtectedRoute element={<HomeScreen />}></ProtectedRoute>
	},
	{
		path: '/home/*',
		element: <ProtectedRoute element={<HomeScreen />}></ProtectedRoute>
	},*/

	{
		path: '/home',
		element: <HomeScreen />,
	},
	{
		path: '/home/*',
		element: <HomeScreen />,
	},
	{
		path: '/profile',
		element: <ProfileScreen />,
	},
	{
		path: '/ratings',
		element: <RatingsScreen />,
	},
	{
		path: '/projects',
		element: <ProjectsScreen />,
	},
	{
		path: '/projects/:projectId',
		element: <ProjectsSlider />,
	},
	{
		path: '/projects/:projectId/game',
		element: <ProjectClicker />,
	},
	{
		path: '*',
		element: <ErrorScreen />,
	},
]);

function App() {
	useEffect(() => {
		//console.log('app loaded')
	}, []);

	return (
		<>
		<Helmet>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P8CSNCKG');
          `}
        </script>
      </Helmet>
	  
		<Provider store={store}>
				<TonConnectUIProvider
					manifestUrl='https://sht-app.prgss.dev/tonconnect-manifest.json'
					actionsConfiguration={{
						twaReturnUrl: 'https://t.me/cln_inline_bot',
					}}
				>
					<PopupProvider>
						<RouterProvider router={router} />
						<Popup />
						<NotificationsContainer />
					</PopupProvider>
				</TonConnectUIProvider>
		</Provider>
		</>
	);
}

export default App;
