import React, { useState, useEffect } from 'react';
import { usePopup } from '../../../../Popups/PopupContext.js';
import Icons from '../../../../Common/IconsComponent.jsx';
import ProjectTasksItem from './ProjectTasksItem';

const ProjectTasks = ({ projectId }) => {
	const { hidePopup } = usePopup();
	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		const projectsData = JSON.parse(localStorage.getItem('projectsData')) || [];

		const extractedTasks = projectsData.flatMap((project) =>
			project.active_tasks
				.filter((task) => project.id === projectId)
				.map((task) => ({
					taskId: task.id,
					name: task.name,
					link: task.link,
					bonus: task.reward,
				}))
		);

		setTasks(extractedTasks);
	}, [projectId]);

	const сloseModal = () => {
		hidePopup();
	};

	return (
		<div className='main__tasks tasks'>
			<div className='modal__close' onClick={сloseModal}>
				<Icons.Close />
			</div>
			<ul className='tasks__list'>
				{tasks.map((task, index) => (
					<li key={index} className='tasks__item'>
						<ProjectTasksItem task={task} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default ProjectTasks;
