import { useState, useCallback } from 'react';
import { useSaveUserActionMutation } from '../services/phpService';
import bcrypt from 'bcryptjs';

  /* Helpers */
  const genHash = async () => {
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC',
    };
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const now = new Date();
    const dateStringWithTime = now.toLocaleString('en-GB', options);	
    const date =  dateStringWithTime; //'26/07/2024, 19:48';
    const crypt = await bcrypt.hash(secretKey + date, 10)
    return crypt;
};

const useSaveUserAction= () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [saveUserAction] = useSaveUserActionMutation();

    const saveAction = useCallback(async (telegram_id, action, parent_telegram_id = null, project_id = null, project_task_id = null) => {
        setLoading(true);
        setError(null);
        try {
            
            sendGaEvent(action, 'partner_'+parent_telegram_id, 'project_'+project_id, 'task_'+project_task_id)

            const token = await genHash();
            let body = {
                token : token,
                id_telegram: telegram_id,
                action: action
            }
            if (project_id) body.id_project = project_id;
            if (project_task_id) body.id_project_task = project_task_id;

            const res = await saveUserAction(body);

            
        } catch (err) {
            console.error('Error saving user action:', err);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [saveUserAction]);

    return { saveAction, loading, error };
};

export default useSaveUserAction;

export const sendGaEvent = (action, category, label, value) => {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };


