import React, { useEffect, useState } from 'react';
import NavigationWidget from '../NavigationWidget';
import Icons from '../../../Common/IconsComponent.jsx';
import { useNavigate } from 'react-router-dom';
import { usePopup } from '../../../Popups/PopupContext';
import {
	useChangeWalletMutation,
	useSetWalletMutation,
} from '../../../../services/phpService.js';
import { useTonAddress, TonConnectButton } from '@tonconnect/ui-react';
import potatoImage from '../../../../assets/img/splash/potato_crown.png';
import { getTelegramShareLink, getReferralLink } from '../../../../helpers/referralLinkHelper';

const ProfileComponent = () => {
	const { user, addNotification, genHash, setUserDataPollingDisabled } = usePopup();
	const [setWallet] = useSetWalletMutation();
	const [changeWallet] = useChangeWalletMutation();
	const [generatedUrl, setGeneratedUrl] = useState('');
	const [ballance, setBallance] = useState(0);
	const [username, setUsername] = useState('');
	const [referrals, setReferrals] = useState(0);
	const [showProfile, setShowProfile] = useState(true);
	const ton_address = useTonAddress(true);
	const tg = window.Telegram.WebApp;
	const initData = tg.initDataUnsafe;
	const [photoUrl, setPhotoUrl] = useState('');

	const [isActive, setIsActive] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (initData) {
			console.log('Telegram User Object:', initData);
			if (initData.user?.photo_url) {
				setPhotoUrl(initData.user.photo_url);
				console.log('Photo URL:', initData.user.photo_url);
			}
		} else {
			console.log('Telegram WebApp or initDataUnsafe is not available');
		}
	}, [initData]);

	const navigate = useNavigate();
	useEffect(() => {
		if (!user) navigate('/?r=profile');
		else
			setUserDataPollingDisabled(false)
	}, []);

	useEffect(() => {
		if (initData) {
			if (initData.user?.photo_url) {
				setPhotoUrl(initData.user.photo_url);
			}
		}
	}, [initData]);

	const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
		timeZone: 'UTC',
	};
	const now = new Date();
	const dateStringWithTime = now.toLocaleString('en-GB', options);

	// ton wallet logic
	const submitWallet = async () => {
		if (ton_address) {
			try {
				const res = await setWallet({
					token: await genHash(),
					wallet_address: ton_address,
					id_telegram: user?.id_telegram,
				}).unwrap();
			} catch (e) {
				console.log(e);
			}
		}
	};

	const updateWallet = async () => {
		if (ton_address) {
			try {
				const res = await changeWallet({
					token: await genHash(),
					wallet_address: ton_address,
					user_id: user?.id,
				}).unwrap();
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		const handleWalletLogic = async () => {
			if (ton_address) {
				console.log('++' + ton_address + '++');
				if (user?.wallet_address === null) {
					await submitWallet();
				} else if (
					user?.wallet_address !== null &&
					ton_address !== user?.wallet_address
				) {
					await updateWallet();
				}
			}
		};

		//handleWalletLogic();
	}, [ton_address, user]);

	useEffect(() => {
		if (user && user.id_telegram) {
			//const referralURL = `t.me/the_shit_money_bot/app?startapp=${user.id_telegram}`;
			const referralURL = getReferralLink(user.id_telegram);
			setGeneratedUrl(referralURL);
			localStorage.setItem('generatedUrl', referralURL);
			setBallance(user.wallet_balance);
			setUsername(user.username);
			setReferrals(user.referrals_count);
		}
	}, [user]);

	// frens
	useEffect(() => {
		const storedUrl = localStorage.getItem('generatedUrl');
		if (storedUrl) {
			setGeneratedUrl(storedUrl);
		}
	}, []);

	const inviteTG = () => {
		/*
		const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
			generatedUrl
		)}&text=${encodeURIComponent('Check out this referral link!')}`;*/

		const shareUrl = getTelegramShareLink(user.id_telegram);
		window.open(shareUrl, '_blank');
	};

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(generatedUrl)
			.then(() => {
				addNotification('success', 'Referral link copied!');
			})
			.catch((err) => {
				addNotification('error', 'Failed to copy');
			});
	};

	return (
		<>
			{showProfile ? (
				<div className='profile'>
					<div className='modal_profile'>
						<div style={{ position: 'absolute', right: '16px', top: '20px' }}>
							<div
								className='btn-ton btn_icon'
								style={{
									border: '2px solid #000',
									borderRadius: '100px',
									color: 'var(--black)',
									padding: '10px 23px',
									boxShadow: isActive || isHovered ? '0 0 0 #000' : '3px 3px 0 #000',
									transform:
										isActive || isHovered ? 'translate(3px, 3px)' : 'translate(0, 0)',
									transition: 'all .1s linear',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									gap: '10px',
									background: '#ffffff',
									position: 'relative',
								}}
								onMouseOver={() => setIsHovered(true)}
								onMouseOut={() => setIsHovered(false)}
								onMouseDown={() => setIsActive(true)}
								onMouseUp={() => setIsActive(false)}
							>
								<Icons.Wallet/>
								<TonConnectButton
									className='tonconnect-btn'
									style={{ position: 'relative' }}
								/>
							</div>
						</div>
						<div className='shit_balance_profile'>Your shit balance:</div>
						<div className='container_count_shit'>
							<span>{ballance}</span>
							<Icons.Shit />
						</div>

						<div className='rive_container_profile'>
							<img src={potatoImage} alt='potato' />
						</div>
						{username ? <div className='profile_user_name'>@{username}</div> : <></>}
						<div className='referals_count'>
							{referrals} referral{referrals > 1 ? 's' : ''}
						</div>

						<button
							className='frens__btn btn animate__fadeInUpBig animate__delay-0.5s'
							onClick={() => setShowProfile(false)}
						>
							<Icons.Invite />
							invite frens
						</button>
					</div>
				</div>
			) : (
				<div className='frens'>
					<div className='frens__main'>
						<div className='frens__container container'>
							<div className='btn btn_icon btn_back' onClick={() => setShowProfile(true)}>
								<Icons.Larrow />
							</div>
							<div className='frens__body'>
								{referrals >= 0 ? (
									<h1 className='frens__title'>
										<Icons.profileFrens/> <br /> {referrals} frens
									</h1>
								) : (
									<h1 className='frens__title'>
										 <br /> frens
									</h1>
								)}
								<p className='frens__text'>Invite frens and earn $hit</p>
								<button
									className='frens__btn btn'
									onClick={inviteTG}
									disabled={!generatedUrl}
								>
									<Icons.Invite />
									invite frens
								</button>
							</div>
						</div>
					</div>
					<div className='frens__options'>
						<div className='frens__container container'>
							<div className='frens__body'>
								<p className='frens__text'>Invite or copy/paste your link</p>
								<button
									onClick={copyToClipboard}
									className='frens__copy btn'
									data-link='referral link'
									data-notification='link-copied'
								>
									<Icons.Copy />
									Copy referral link
								</button>
							</div>
							<div className='frens__more'>
								<p className='frens__text'>More info</p>
								<div className='frens__moreItem'>
									<div className='frens__moreItem-group'>
										<div className='frens__moreItem-title'>
											<h2>invite a friend</h2>
										</div>
										<div className='frens__moreItem-text'>
											<p>with telegram premium </p>
										</div>
									</div>
									<div className='frens__moreItem-item'>
										<span>
											+3,0 <Icons.Shit />
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
};

export default ProfileComponent;
