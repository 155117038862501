import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Icons from '../../Common/IconsComponent.jsx';

const NavigationWidget = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [activePath, setActivePath] = useState(location.pathname);

	useEffect(() => {
		setActivePath(location.pathname);
	}, [location.pathname]);

	const navigateTo = async (path) => {
		navigate(path);
		setActivePath(path);
	};

	return (
		<footer className='footer'>
			<div className='footer__container container'>
				<div className='footer__body'>
					<nav className='footer__menu'>
						<ul className='footer__list'>
							<li className='footer__item'>
								<button onClick={() => navigateTo('/home')} className='footer__link'>
									{activePath === '/home' ? <Icons.Shit /> : <Icons.ShitWhite />}
									the $hit
								</button>
							</li>
							<li className='footer__item'>
								<button onClick={() => navigateTo('/projects')} className='footer__link'>
									{activePath === '/projects' ? (
										<Icons.Projects />
									) : (
										<Icons.ProjectsWhite />
									)}
									projects
								</button>
							</li>
							<li className='footer__item'>
								<button onClick={() => navigateTo('/ratings')} className='footer__link'>
									{activePath === '/ratings' ? <Icons.Cup /> : <Icons.CupWhite />}
									ratings
								</button>
							</li>
							<li className='footer__item'>
								<button onClick={() => navigateTo('/profile')} className='footer__link'>
									{activePath === '/profile' ? <Icons.User /> : <Icons.UserWhite />}
									profile
								</button>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	);
};

export default NavigationWidget;
