import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import NavigationWidget from '../NavigationWidget';
import {
	useGetLeaderboardMutation,
	useGetTopVotersMutation,
	useGetTopReferralsMutation,
} from '../../../../services/phpService';
import Icons from '../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../Popups/PopupContext';

const RatingsComponent = () => {
	const [getLeaderboard] = useGetLeaderboardMutation();
	const [getTopVoters] = useGetTopVotersMutation();
	const [getTopReferrals] = useGetTopReferralsMutation();
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [topVotersData, setTopVotersData] = useState([]);
	const [topReferralsData, setTopReferralsData] = useState([]);
	const [activeTab, setActiveTab] = useState('leaderboard');
	const [isLoading, setIsLoading] = useState(true);
	const { user } = usePopup();

	const navigate = useNavigate();

	useEffect(() => {
		if (!user) {
			navigate('/?r=ratings');
		} else {
			setIsLoading(true);
		}
	}, [user]);

	const fetchData = async (tab) => {
		try {
			setIsLoading(true);
			const loadTimeStart = Date.now();

			let res;
			switch (tab) {
				case 'leaderboard':
					res = await getLeaderboard(user.id_telegram);
					if (res?.data) {
						setLeaderboardData(res.data);
					}
					break;
				case 'topVoters':
					res = await getTopVoters(user.id_telegram);
					if (res?.data) {
						setTopVotersData(res.data);
					}
					break;
				case 'topReferrals':
					res = await getTopReferrals(user.id_telegram);
					if (res?.data) {
						setTopReferralsData(res.data);
					}
					break;
				default:
					console.warn('No fetch method for this path');
					break;
			}

			const loadTimeEnd = Date.now();
			const elapsedTime = loadTimeEnd - loadTimeStart;
			const remainingTime = 750 - elapsedTime;

			if (remainingTime > 0) {
				setTimeout(() => setIsLoading(false), remainingTime);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			console.error(`Error fetching data for ${tab}:`, error);
		}
	};

	useEffect(() => {
		fetchData(activeTab);
	}, [activeTab]);

	const getBalanceField = (player) => {
		switch (activeTab) {
			case 'leaderboard':
				return player.wallet_balance;
			case 'topVoters':
				return player.total_votes;
			case 'topReferrals':
				return player.referrals_count;
			default:
				return '';
		}
	};

	const [containerWidth, setContainerWidth] = useState(0);
	const containerRef = useRef(null);

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	const numberOfRects = 12;
	const rects = [];

	for (let i = 0; i < numberOfRects; i++) {
		rects.push(
			<rect key={i} x='0' y={i * 58} rx='16' ry='16' width={containerWidth} height='52' />
		);
	}

	const renderTabContent = (data) => {
		return (
			<ul className='ratings__list'>
				{data.map((player, index) => {
					const isCurrentUser = player.username === user.username;
					const isTopThree = index < 2;
					return (
						<li
							className={`ratings__listItem ${
								isCurrentUser && !isTopThree ? 'highlight' : ''
							}`}
							key={index}
						>
							<div className='ratings__itemData'>
								<div className='ratings__listId'>
									<Icons.PlayerId />
									<span>{player.position}</span>
								</div>
								<div className='ratings__listName'>
									<span>
										{player.username?.length > 10
											? `${player.username.substring(0, 10)}...`
											: player.username ?? 'anonymous'}
									</span>
								</div>
								<div className='ratings__listBalance'>
									<span>{getBalanceField(player)}</span>
									{activeTab !== 'topReferrals' && <Icons.Shit />}
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		);
	};

	return (
		<>
			<div className='ratings'>
				<div className='ratings__container container'>
					<div className='ratings__body' ref={containerRef}>
						<h1 className='ratings__title'>Leaderboard</h1>
						<div className='ratings__links'>
							<button
								className={`tab-btn ratings__btn btn ${
									activeTab === 'leaderboard' ? 'active' : ''
								}`}
								onClick={() => setActiveTab('leaderboard')}
							>
								top point
							</button>

							<button
								className={`tab-btn ratings__btn btn ${
									activeTab === 'topReferrals' ? 'active' : ''
								}`}
								onClick={() => setActiveTab('topReferrals')}
							>
								top referrals
							</button>

							<button
								className={`tab-btn ratings__btn btn ${
									activeTab === 'topVoters' ? 'active' : ''
								}`}
								onClick={() => setActiveTab('topVoters')}
							>
								top activity
							</button>
						</div>

						{isLoading ? (
							<ContentLoader
								speed={2}
								width={'100%'}
								height={'100%'}
								viewBox='0 0 {containerWidth} 700'
								backgroundColor='#f3f3f3'
								foregroundColor='#ecebeb'
							>
								{/* ContentLoader shapes */}
								{rects}

								<rect x='0' y='0' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='58' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='116' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='174' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='232' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='290' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='348' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='406' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='464' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='522' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='580' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='638' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='696' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='754' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='812' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='870' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='928' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='986' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='1,044' rx='16' ry='16' width='350' height='52' />
								<rect x='0' y='1,102' rx='16' ry='16' width='350' height='52' />
							</ContentLoader>
						) : (
							<>
								{activeTab === 'leaderboard' && renderTabContent(leaderboardData)}
								{activeTab === 'topVoters' && renderTabContent(topVotersData)}
								{activeTab === 'topReferrals' && renderTabContent(topReferralsData)}
							</>
						)}
					</div>
				</div>
			</div>
			<NavigationWidget />
		</>
	);
};

export default RatingsComponent;
