import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useGetUserByTgIdQuery } from '../../../services/phpService';
import Icons from '../../Common/IconsComponent';
import { usePopup } from '../../Popups/PopupContext.js';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import heroAnimation from '../../../assets/animations/potato.riv';
import WebFont from 'webfontloader';
import QRscreen from '../QRscreen/QRscreen.jsx';

const SplashScreen = () => {
	const { login, setUser, addNotification, isAuthenticated, setUserDataPollingDisabled } = usePopup();
	const tg = window.Telegram.WebApp;
	const initData = tg.initDataUnsafe;
	const isDevMode = process.env.NODE_ENV === 'development';
	const isLocalhost = window.location.hostname === 'localhost';
	const timerRef = useRef(null);
	const [fontsLoaded, setFontsLoaded] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [starting_project_id, setProjectId] = useState(0); // project ID to open on start
	const [userFetchAttempts, setUserFetchAttempts] = useState(0);
	const secretURL = process.env.REACT_APP_REGISTER_KEY;
	const navigate = useNavigate();
	const [pausePorgressBar, setPausePorgressBar] = useState(false);

	const isMobileDevice =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	// const isMobileDevice = true;

	let userId;
	let processAuth;

	if (isDevMode && isLocalhost) {
		userId = 7453951668;//321967834; //7453951668; // 400815943 // 64288442 // 123456789842 // 321967834
		processAuth = true;
	} else {
		userId = initData?.user?.id;
		processAuth = !!initData?.user?.id;
	}
	if (userId) {
		localStorage.setItem('userID', userId);
	}
	
	const [skip, setSkip] = useState(true);
	const { data: userData, isLoading, isError, error } = useGetUserByTgIdQuery(userId, {
		skip: skip,
		pollingInterval: 2000,
	});

	// Check fonts
	useEffect(() => {
		WebFont.load({
			custom: {
				families: ['Lacquer'],
				urls: ['/fonts.css'],
			},
			active: () => {
				setFontsLoaded(true);
			},
		});
	}, []);

	useEffect(() => {
		if (processAuth) {
			pagePreloader(3000, pausePorgressBar);
			setSkip(false);
		} else {
			addNotification('info', 'Please run application in Telegram App');
			setSkip(true);
		}
	}, []);

	const parseStartParam = (startParam) => {
		let parent_user_telegram_id = null;
		let project_id = null;
		console.log(startParam)

		// Check if the start_param contains both 'u' (user) and 'p' (project)
		if (/u-?\d+p\d+/.test(startParam)) {
			const userIdMatch = startParam.match(/u(-?\d+)/);
			const projectIdMatch = startParam.match(/p(\d+)/);
			parent_user_telegram_id = userIdMatch ? userIdMatch[1] : null;
			project_id = projectIdMatch ? projectIdMatch[1] : null;
		}
		// Check if the start_param contains only 'u' (user)
		else if (/u-?\d+/.test(startParam)) {
			const userIdMatch = startParam.match(/u(-?\d+)/);
			parent_user_telegram_id = userIdMatch ? userIdMatch[1] : null;
		}
		// Check if the start_param contains only 'p' (project)
		else if (/p\d+/.test(startParam)) {
			const projectIdMatch = startParam.match(/p(\d+)/);
			project_id = projectIdMatch ? projectIdMatch[1] : null;
		}
		// Otherwise, assume the start_param contains only the user_id
		else if (/^-?\d+$/.test(startParam)) {
			parent_user_telegram_id = startParam;
		}

		console.log(parent_user_telegram_id, project_id)
		return { parent_user_telegram_id, project_id };
	};

	const navigateToStartScreen = (project_id) => {
		
		const queryParams = new URLSearchParams(window.location.search);
		const redirectParam = queryParams.get('r');
		if (project_id) navigate('/projects/' + project_id);
		else if (redirectParam) navigate(redirectParam);
		else navigate('/home');
	};

	useEffect(() => {
		const { parent_user_telegram_id, project_id } = parseStartParam(
			initData?.start_param
		);
		alert ('Referral params check \nParent user id: '+parent_user_telegram_id+'\nProject_id: '+project_id);
		setProjectId(project_id);
		const registerUser = async () => {
			try {
				const body = {
					query_id: initData?.query_id,
					user: {
						id: initData?.user?.id,
						is_bot: initData?.user?.is_bot,
						is_premium: initData?.user?.is_premium,
						first_name: initData?.user?.first_name,
						last_name: initData?.user?.last_name,
						username: initData?.user?.username,
						language_code: initData?.user?.language_code,
					},
					auth_date: initData?.auth_date,
					hash: initData?.hash,
					timezone: moment.tz.guess(), // Automatically get the user's timezone
				};

				//alert ('Registering user \nParent user id: '+parent_user_telegram_id+'\nProject_id: '+project_id);
				// Conditionally add parent_id_telegram
				if (parent_user_telegram_id) {
					body.parent_id_telegram = parent_user_telegram_id;
				}

				// Adding project id that was spesified in refferal link
				if (project_id) {
					body.referral_project_id = project_id;
				}

				console.log(body);
				const response = await fetch(`${secretURL}/register`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(body),
				});
				
				if (!response.ok) {
					// if user exists - getting info from php backend
					if (response.status == 409)
					{
						setSkip(false); // settign polling skip to false
					}
					else {
						setSkip(true);
						setPausePorgressBar(1)
						addNotification('error', 'Oops! Something went wrong. Please try again later!');
						throw new Error('Network response was not ok');
					}
				}
				else setSkip(false);

			} catch (error) {
				console.error('Error:', error);
			}
		};

		if (!isLoading && processAuth) {

			setUserFetchAttempts(userFetchAttempts + 1);

			// if there was lot of tries - stop trying 
			if (userFetchAttempts > 3)
			{
				setSkip(true);
				setPausePorgressBar(1)
				addNotification('error', 'Oops! Something went wrong. Please try again later!');
				return;
			}

			if (isError)
				{
					if (error.status == 404)
					{
						if (isDevMode) console.log('registering user');
						registerUser();
					}
					else {
						setPausePorgressBar(1)
						addNotification('error', 'Oops! Something went wrong. Please try again later!');
						setSkip(true);
					}
					return;
				}

			if (userData && userData?.id_telegram) {
				setUser(userData);
				login();
			}
		}
	}, [initData, userData, secretURL, isLoading, error]);

	const [animationLoaded, setAnimationLoaded] = useState(false);
	const { RiveComponent } = useRive({
		src: heroAnimation,
		onLoad: () => {
			setAnimationLoaded(true);
		},
	});

	const pausePorgressBarRef = useRef(pausePorgressBar);
	useEffect(() => {
		pausePorgressBarRef.current = pausePorgressBar;
	}, [pausePorgressBar]);

	// preloader progress animation
	const pagePreloader = (time) => {
		const preloader = document.querySelector('.preloader');

		if (preloader) {
			const percent = preloader.querySelector('.preloader__percent span');
			const progressBar = preloader.querySelector('.preloader__bar svg');
			let count = 0;
			let progress = 450;
			let iterationCount = 0;
			const maxIterations = 90;

			const updateProgress = () => {
				if (pausePorgressBarRef.current) 
					{
						progressBar.style.cssText = 'stroke-dashoffset: 0';
						return;
					}
				count++;
				progress = 450 + (450 / 100) * count;

				// percent.innerHTML = count;
				progressBar.style.cssText = 'stroke-dashoffset: ' + -progress;

				if (count >= 100) {
					count = 0;
					progress = 450;
					iterationCount++;

					if (iterationCount >= maxIterations) {
						clearInterval(interval);
					}
				}
			};

			const interval = setInterval(updateProgress, time / 100);

			return () => clearInterval(interval);
		}
	};

	const animationLoadedRef = useRef(animationLoaded);
	const fontsLoadedRef = useRef(fontsLoaded);
	const isAuthenticatedRef = useRef(isAuthenticated);

	useEffect(() => {
		animationLoadedRef.current = animationLoaded;
	}, [animationLoaded]);

	useEffect(() => {
		fontsLoadedRef.current = fontsLoaded;
	}, [fontsLoaded]);

	useEffect(() => {
		isAuthenticatedRef.current = isAuthenticated;
	}, [isAuthenticated]);

	const starting_project_id_ref = useRef(starting_project_id);

	useEffect(() => {
		starting_project_id_ref.current = starting_project_id;
	}, [starting_project_id]);

	const attemptsRef = useRef(attempts);
	useEffect(() => {
		attemptsRef.current = attempts;
	}, [attemptsRef]);

	useEffect(() => {
		/*
		const interval = setInterval(() => {
			if (animationLoadedRef.current && isAuthenticatedRef.current) {
				if (fontsLoadedRef.current)
					navigateToStartScreen(starting_project_id_ref.current);
				else if (attemptsRef.current > 2 * 5)
					navigateToStartScreen(starting_project_id_ref.current);

				setAttempts((prev) => prev + 1);

				clearInterval(interval);
			}
		}, 500);*/

		//return () => clearInterval(interval);
	}, [navigate]);

	useEffect(() => {
		if (isAuthenticated)
			navigateToStartScreen(starting_project_id_ref.current);
	},[isAuthenticated])

	return (
		<div className='preloader-container'>
			{!isMobileDevice ? (
				<QRscreen />
			) : (
				<div className='preloader active'>
					<div className='preloader__body'>
						{/*<h2 className='preloader__title'>Please wait...</h2>*/}
						<div className='preloader__bar'>
							<div className='preloader__image'>
								{/* <img src={imgPreloader} alt='The $hit' /> */}
								<RiveComponent style={{ display: 'none' }} />
								<div className='preloader_image_in'></div>
							</div>
							<Icons.Loader />
						</div>
						<div className='preloader__percent'>{/* <span>0</span>% */}</div>
						{/* <h1 className='preloader__app_title'>the $hit</h1>
				<span className='preloader__app_subtitle'>vote and earn</span> */}
					</div>
				</div>
			)}
		</div>
	);
};

export default SplashScreen;
