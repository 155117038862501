import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import Project from '../Project/Project';
import NavigationWidget from '../../NavigationWidget';
import { useParams, useLocation  } from 'react-router-dom';
import './ProjectsSlider.scss';
import useFetchProjects from '../../../../../helpers/fetchProjects';
import { useNavigate } from 'react-router-dom';
import { usePopup } from '../../../../Popups/PopupContext.js';

export default function ProjectsSlider() {
	const [projects, setProjects] = useState([]);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const baseURL = process.env.REACT_APP_SECRET_IMG;
	const { projectId } = useParams();
	const { user } = usePopup();
	const navigate = useNavigate();
	const location = useLocation();

	const { projectsData, fetchAllProjects, loading} = useFetchProjects();

	useEffect(() => {
		fetchAllProjects();
	}, []);

	useEffect(() => {
		const redirect_path = location.pathname.substring(1);
		if (!user) navigate('/?r='+redirect_path);
	}, []);

	useEffect(() => {
		// checking if projects fetched
		if (!loading && projectsData) {
			// Sort projects by votes (descending)
			const sortedProjects = [...projectsData].sort(
				(a, b) => b.vote_total - a.vote_total
			);

			const mappedProjects = sortedProjects.map((project, index) => {
				const valueVoters =
					project.total_voters && project.total_voters.length > 0
						? project.total_voters[0].total_voters
						: null;

				return {
					id: project.id,
					value: project.vote_total || null,
					valueVoters,
					rank: index + 1,
					has_game: project.has_game,
					imgSrc: `${baseURL}${project.image}`,
					name: project.name,
					tokenName: project.tokenName || null,
					contract: project.contract || null,
					tasksCount: `${project.active_tasks.length}`,
					tasks: project.active_tasks.map((task) => ({
						taskId: task.id,
						name: task.name,
						value: `+${task.reward}`,
						link: task.link || 'home',
						imgSrc: `${baseURL}${project.image}`,
					})),
				};
			});

			setProjects(mappedProjects);

			// Get initial slide index based on projectId
			const index = mappedProjects.findIndex(
				(project) => project.id.toString() === projectId
			);
			setInitialSlideIndex(index >= 0 ? index : 0);
		}
	}, [baseURL, projectId, loading, projectsData]);


	return (
		<>
			{projects.length > 0 && (
				<div className='projects'>
					<div className='projects__body'>
						<div className='projects__slider'>
							<Swiper
								modules={[Navigation]}
								spaceBetween={50}
								slidesPerView={1}
								loop={true}
								initialSlide={initialSlideIndex}
							>
								{projects.map((project) => (
									<SwiperSlide key={project.id}>
										<Project project={project} />
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
					<NavigationWidget />
				</div>
			)}
		</>
	);
}
